import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UserService} from './users.service';
import {ElectricityService} from './electricity.service';
import {SmartTableService} from './smart-table.service';
import {UserActivityService} from './user-activity.service';
import {OrdersChartService} from './orders-chart.service';
import {ProfitChartService} from './profit-chart.service';
import {TrafficListService} from './traffic-list.service';
import {PeriodsService} from './periods.service';
import {EarningService} from './earning.service';
import {OrdersProfitChartService} from './orders-profit-chart.service';
import {TrafficBarService} from './traffic-bar.service';
import {ProfitBarAnimationChartService} from './profit-bar-animation-chart.service';
import {TemperatureHumidityService} from './temperature-humidity.service';
import {SolarService} from './solar.service';
import {TrafficChartService} from './traffic-chart.service';
import {StatsBarService} from './stats-bar.service';
import {CountryOrderService} from './country-order.service';
import {StatsProgressBarService} from './stats-progress-bar.service';
import {VisitorsAnalyticsService} from './visitors-analytics.service';
import {SecurityCamerasService} from './security-cameras.service';
import {OrderService} from "../api/order.service";
import {DateReminderService} from "../api/date-reminder.service";
import {ProfileService} from "../api/profile.service";
import {DashboardService} from "../api/dashboard.service";
import {AddressesService} from "../api/addresses.service";
import {WalletService} from "../api/wallet.service";
import {AddFriend} from "../api/addfriend.service";
import {AffiliateService} from "../api/affiliate.service";
import {DiscountService} from "../api/discount.service";
import {AuthService} from "../api/auth.service";

const SERVICES = [
  ProfileService,
  DateReminderService,
  DashboardService,
  AddressesService,
  AuthService,
  DiscountService,
  AffiliateService,
  OrderService,
  UserService,
  WalletService,
  AddFriend,
  /*ElectricityService,
  SmartTableService,
  UserActivityService,
  OrdersChartService,
  ProfitChartService,
  TrafficListService,
  PeriodsService,
  EarningService,
  OrdersProfitChartService,
  TrafficBarService,
  ProfitBarAnimationChartService,
  TemperatureHumidityService,
  SolarService,
  TrafficChartService,
  StatsBarService,
  CountryOrderService,
  StatsProgressBarService,
  VisitorsAnalyticsService,
  SecurityCamerasService,*/
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
})
export class MockDataModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: MockDataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
