import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {AuthGuard} from "./auth-guard.service";

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('app/panel/panel.module')
      .then(m => m.PanelModule),
  },
  {
    path: 'auth',
    loadChildren: './panel/auth/auth.module#NgxAuthModule',
  },
  //{ path: '', redirectTo: 'pages', pathMatch: 'full' },
  {path: '**', redirectTo: ''},
];

const config: ExtraOptions = {
    useHash: false,
    relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
