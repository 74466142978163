import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CONSTANTS} from "../../CONSTANATS";
import {AuthData} from "../data/auth-data";

@Injectable()
export class AuthService extends AuthData {
  public API = CONSTANTS.API_URL + 'auth/';
  public API2 = CONSTANTS.SERVER_URL + 'wp-json/jwt-auth/v1/token';

  constructor(private httpClient: HttpClient) {
    super();
  }

  checkMobile(phoneNumber) {
    return this.httpClient.post<any>(
      this.API + 'checkMobileAuth', {phoneNumber}
    );
  }


  sendAuthCode(phoneNumber) {
    return this.httpClient.post(
      this.API + 'sendMobileAuth', {phoneNumber}
    );
  }


  phoneNumberAuth(phoneNumber, authCode, password) {
    return this.httpClient.post<any>(
      this.API + 'phoneNumberAuth', {phoneNumber, authCode,password}
    );
  }

  login(username,password){
    return this.httpClient.post<any>(
      this.API2, {username, password}
    );
  }

  register(username,password) {
    return this.httpClient.post<any>(
      this.API + 'register', {username, password}
    );
  }
}
