export abstract class AuthData {
  abstract sendAuthCode(phoneNumber);

  abstract phoneNumberAuth(phoneNumber, authCode, password);

  abstract login(username, password);

  abstract register(username, password) ;

}
