import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ProfileData} from "../data/profile-data";
import {ProfileModel} from "../model/Profile.model";
import {CONSTANTS} from "../../CONSTANATS";

@Injectable()
export class ProfileService extends ProfileData {
  public API = CONSTANTS.API_URL + 'profile';

  constructor(private httpClient: HttpClient) {
    super();
  }

  getData() {
    return this.httpClient.get<object[]>(
      this.API
    );
  }

  get() {
    return this.httpClient.get<ProfileModel>(
      this.API
    );
  }

  update_img(img) {
    return this.httpClient.post(
      this.API+ '/update_img',img
    );
  }


  update(profile) {
    return this.httpClient.post<ProfileModel>(
      this.API, {user: profile}
    );
  }

  changePassword(pass, passN, passNR) {
    return this.httpClient.post<ProfileModel>(
      this.API+ '/changePassword', {password : {pass, passN, passNR}}
    );
  }
}
