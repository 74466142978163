export class CONSTANTS {

  /*
    static SERVER_URL = 'http://localhost/';
    static SERVER_URL = 'https://www.golsetan.com/';
  */

  static SERVER_URL = 'https://www.golsetan.com/';
  static API_URL = CONSTANTS.SERVER_URL + 'wp-json/panel/v1/';
  static VERSION = '1.0.0';
}
