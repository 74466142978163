import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CONSTANTS} from "../../CONSTANATS";
import {AddressesData} from "../data/addresses-data";
import {AddressModel} from "../model/Address.model";
import {AffiliateData} from "../data/affiliate-data";

@Injectable()
export class AffiliateService extends AffiliateData {
  public API = CONSTANTS.API_URL + 'affiliate/';

  constructor(private httpClient: HttpClient) {
    super();
  }

  get() {
    return this.httpClient.get<any>(
      this.API
    );
  }

  getFreeChargeWallet() {
    return this.httpClient.get<any>(
      this.API + 'getFreeCharge'
    );
  }

  transferAffiliateWalletToMainWallet() {
    return this.httpClient.get<any>(
      this.API + 'transferAffiliateToMainWallet'
    );
  }

  withdrawRequest() {
    return this.httpClient.get<any>(
      this.API + 'withdrawRequest'
    );

  }
}
