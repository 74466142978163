import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {OrderData} from "../data/order-data";
import {CONSTANTS} from "../../CONSTANATS";

@Injectable()
export class OrderService extends OrderData {
  public API = CONSTANTS.API_URL + 'orders';
  public API2 = CONSTANTS.API_URL + 'order/';

  public statusLogos = {
    'pending': 'https://www.golsetan.com/wp-content/uploads/2020/01/Cansel-Order.png',
    'on-hold': 'https://www.golsetan.com/wp-content/uploads/2020/01/Cansel-Order.png',
    'failed': 'https://www.golsetan.com/wp-content/uploads/2020/01/Cansel-Order.png',
    'cancelled': 'https://www.golsetan.com/wp-content/uploads/2020/01/Cansel-Order.png',
    'completed': 'https://www.golsetan.com/wp-content/uploads/2020/01/delivered.png',
    'processing': 'https://www.golsetan.com/wp-content/uploads/2020/01/success-request.png',
    'preparing': 'https://www.golsetan.com/wp-content/uploads/2020/01/designing-order.png',
    'shipping-ready': 'https://www.golsetan.com/wp-content/uploads/2020/01/designed.png',
    'shipping': 'https://www.golsetan.com/wp-content/uploads/2020/01/delivery-porgres.png'
  };
  public hiddenStatuses = ['cancelled', 'refunded', 'failed', 'printed', 'on-hold', 'pending'];


  constructor(private httpClient: HttpClient) {
    super();
  }

  getData() {
    return this.httpClient.get<object[]>(
      this.API
    );
  }

  getOrderStatuses() {
    return this.httpClient.get<any>(
      this.API2+ 'statuses'
    );
  }

  getOrder(orderId) {
    return this.httpClient.get<object[]>(
      this.API2 + orderId
    );
  }
}
