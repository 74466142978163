import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {NbMenuService, NbSidebarService} from "@nebular/theme";
import {LayoutService} from "../../../@core/utils";

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar start class="menu-sidebar" [state]="sidebarState"
                  tag="menu-sidebar" [responsive]="true">
        <div #menuWidth>
          <ng-content select="nb-menu"></ng-content>
        </div>
      </nb-sidebar>

      <nb-layout-column *ngIf="showContent">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <!--<nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>-->
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnInit {
  public sidebarState = "expanded";
  public showContent = true;
  @ViewChild('menuWidth', {static: true, read: ElementRef}) menuWidth: ElementRef;

  constructor(menu: NbMenuService,
              private sidebarService: NbSidebarService,
              private layoutService: LayoutService) {
    this.changeState(window.innerWidth);

    // collapse menu on item click
    menu.onItemClick().subscribe(() => {
      if (window.innerWidth <= 768) {
        this.toggleSidebar();
      }
    });

  }

  ngOnInit() {
    this.sidebarService.onToggle().subscribe(value => {
      console.log(this.menuWidth.nativeElement.offsetWidth);
      console.log(window.innerWidth);
      if (window.innerWidth < 650) {
        this.showContent = (this.menuWidth.nativeElement.offsetWidth > 20);
      } else {
        this.showContent = true;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.changeState(event.target.innerWidth);
  }

  changeState(width) {
    if (width <= 768) {
      this.sidebarState = 'collapsed';
    }
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }
}
