import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NbTokenService} from "@nebular/auth";
import {fromPromise} from 'rxjs/internal-compatibility';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private tokenService: NbTokenService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('auth')) {
      return next.handle(request);
    }
    return fromPromise(this.tokenService.get().toPromise())
      .pipe(
        switchMap(token => {
          const cityHeader = request.clone({
            headers: request.headers.append('Authorization', 'Bearer ' + token)
          });

          return next.handle(cityHeader);
        })
      );

    //return next.handle(request);
  }
}
