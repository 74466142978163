import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CONSTANTS} from "../../CONSTANATS";
import {AddressesData} from "../data/addresses-data";
import {AddressModel} from "../model/Address.model";

@Injectable()
export class AddressesService extends AddressesData {
  public API = CONSTANTS.API_URL + 'addresses/';

  constructor(private httpClient: HttpClient) {
    super();
  }

  get() {
    return this.httpClient.get<any>(
      this.API
    );
  }

  save(addresses) {
    return this.httpClient.post<AddressModel[]>(
      this.API, {addresses}
    );
  }



}
