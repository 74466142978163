export abstract class ProfileData {
  abstract getData();

  abstract get();

  abstract update(profile);

  abstract update_img(img);

  abstract changePassword(pass, passN, passNR);
}
