import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CONSTANTS} from "../../CONSTANATS";
import {DashboardData} from "../data/dashboard-data";

@Injectable()
export class DashboardService extends DashboardData {
  public API = CONSTANTS.API_URL + 'dashboard';

  constructor(private httpClient: HttpClient) {
    super();
  }

  get() {
    return this.httpClient.get<object[]>(
      this.API
    );
  }


}
