import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CONSTANTS} from "../../CONSTANATS";
import {AddressesData} from "../data/addresses-data";
import {AddressModel} from "../model/Address.model";
import {AffiliateData} from "../data/affiliate-data";
import {DiscountData} from "../data/discount-data";

@Injectable()
export class DiscountService extends DiscountData {
  public API = CONSTANTS.API_URL + 'discount';

  constructor(private httpClient: HttpClient) {
    super();
  }

  get() {
    return this.httpClient.get<any>(
      this.API
    );
  }


}
