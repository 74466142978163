import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ProfileData} from "../data/profile-data";
import {ProfileModel} from "../model/Profile.model";
import {CONSTANTS} from "../../CONSTANATS";
import {WalletData} from "../data/wallet-data";


@Injectable()
export class AddFriend extends WalletData {
  public API = CONSTANTS.API_URL + 'dashboard/wallet/';

  constructor(private httpClient: HttpClient) {
    super();
  }

  get() {
    return this.httpClient.get<any>(
      this.API
    );
  }

  getAmounts() {
    return this.httpClient.get<any>(
      this.API + 'amounts'
    );
  }

  addCredit(mobile_friend, name_friend) {
    return this.httpClient.post<any>(
      this.API + 'addfriend', {mobile_friend, name_friend}
    );
  }

}
