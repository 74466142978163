import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DateReminderData} from "../data/date-remnder-data";
import {DateReminderModel} from "../model/DateReminder.model";
import {CONSTANTS} from "../../CONSTANATS";

@Injectable()
export class DateReminderService extends DateReminderData {
  public API = CONSTANTS.API_URL + 'dateReminders/';

  constructor(private httpClient: HttpClient) {
    super();
  }

  get() {
    return this.httpClient.get<any>(
      this.API
    );
  }

  save(reminders) {
    return this.httpClient.post<DateReminderModel[]>(
      this.API, {reminders}
    );
  }
}
